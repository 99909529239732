














































import { Component, VModel, Mixins } from 'vue-property-decorator';
import {
  BindedChannelGreetingProtection
} from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection/ReputationCalculationMode";
import BaseProtectionTypeFields from "./BaseProtectionTypeFields.vue";
import ChannelBinding from "@/components/chat/ChannelBinding.vue";
import { InputSetups } from "@/mixins/input-setups";
import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue';
import { atSignedLogin, tgLoginLink } from "piramis-base-components/src/shared/utils/tgLoginHelpers";
import { ChatConfig } from "@/includes/types/Chat/types";

@Component({
  components: {
    TextInput,
    ChannelBinding,
    BaseProtectionTypeFields
  }
})
export default class BindedChannelGreetingProtectionView extends Mixins(InputSetups) {
  @VModel() model!: BindedChannelGreetingProtection

  get bindChannelInfo(): string {
    if(this.$store.state.chatState.chat.brand) {
      return this.$t('bind_channel_post_alert_greeting', { '0': atSignedLogin(this.$store.state.chatState.chat.brand.botname), '1': tgLoginLink(this.$store.state.chatState.chat.brand.botname) }).toString()
    } else {
      return this.$t('bind_channel_post_alert_greeting', { '0': `@ChatKeeperBot`, '1': `https://t.me/chatkeeperbot` }).toString()
    }
  }

  bindFinish(result: { channelId: ChatConfig['binded_channel'], invite_link: string }) {
    const { channelId, invite_link } = result

    if (invite_link) {
      this.model.invite_link = invite_link
    }
  }
}
