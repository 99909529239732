













import { Vue, Component, VModel } from 'vue-property-decorator';
import {
  MathCaptchaGreetingProtection
} from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection/ReputationCalculationMode";
import BaseProtectionTypeFields from "./BaseProtectionTypeFields.vue";

@Component({
  components: {
    BaseProtectionTypeFields
  }
})
export default class MathCaptchaGreetingProtectionView extends Vue {
  @VModel() model!: MathCaptchaGreetingProtection
}
