var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-protection-type-fields"},[_c('switch-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'strictMode',
        'prefix': 'greeting_',
        'hasAnchor': true,
      }
    }}}),_c('time-unit-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'removeNotConfirmUsersTimeoutModel',
        'unit': _vm.StepUnit.Second,
        'settingWrapper': {
          customiseValue: _vm.StepUnit.Minute * 5,
          hasFieldButton: true,
          disabledValue: 0
        },
        'hasAnchor': true,
      }
    }}}),(_vm.removeNotConfirmUsersTimeoutModel > 0)?_c('nested-content',[_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': this,
          'key': 'banUnverifiedUsersTimeModel',
          'tariffTags': _vm.getTagsByFieldKey('ban_unverified_users_time'),
          'unit': _vm.StepUnit.Second,
          'settingWrapper': {
            customiseValue: _vm.StepUnit.Minute,
            hasFieldButton: true,
            disabledValue: 0
          },
          'hasAnchor': true,
        }
      }}})],1):_vm._e(),_c('switch-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'removeHellowAfterButtonPressedModel',
        'disabled': !_vm.$store.getters.isChatLicenseExists,
        'tariffTags': _vm.getTagsByFieldKey('field_remove_hellow_after_button_pressed'),
        'hasAnchor': true,
      }
    }}}),(!_vm.removeHellowAfterButtonPressedModel)?_c('nested-content',[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': this,
          'key': 'removeHellowButtonModel',
          'hasAnchor': true,
        }
      }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }